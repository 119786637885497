import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { navigate } from "gatsby";

import { updateProfile, uploadMedicSignature } from "~/src/api-client/medics";
import DashboardLayout from "~/src/components/DashboardLayout";
import { useContextUpdate, useContextValue } from "~/src/context";
import LoadingSpinnerIcon from "~/src/icons/LoadingSpinnerIcon";
import { Medic } from "~/src/types/medic";
import namespaced from "~/src/utils/debug";

const debug = namespaced("pages/profile");

const DoctorProfile = () => {
  const { medic, session, rehydrated } = useContextValue();
  const { setMedicKeys } = useContextUpdate();
  const [updating, setUpdating] = useState<boolean>(false);
  const [medicData, setMedicData] = useState<Partial<Medic>>({});
  const [errorUpload, setErrorUpload] = useState<boolean>(false);
  const [fileToUpload, setFileToUpload] = useState<File | null>(null);

  useEffect(() => {
    if (rehydrated) {
      if (!session) {
        navigate("/");
      } else if (medic && !medic.active) {
        navigate("/app");
      }
    }
  }, [rehydrated, session, medic]);

  const updateMedicProfile = async () => {
    setUpdating(true);
    try {
      if (session) {
        const updateData = medicData;
        if (fileToUpload) {
          const resFile = await uploadMedicSignature(session.accessToken, [fileToUpload]);
          if (resFile) {
            setMedicKeys({ ...medic, ...resFile.data });
          } else {
            debug(`[updateMedicProfile] failed to upload file`);
          }
        }
        if (updateData.title !== medic.title || updateData.subtitle !== medic.subtitle) {
          const res = await updateProfile(session.accessToken, updateData);
          if (res) {
            setMedicKeys({ ...medic, ...res.data });
          } else {
            debug(`[updateMedicProfile] failed to update medic`);
          }
        }
      }
    } catch (err) {
      debug(`[updateMedicProfile]`, err);
    } finally {
      setUpdating(false);
    }
  };

  useEffect(() => {
    setMedicData(medic);
  }, [medic]);

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorUpload(false);
    if (event.target.files) {
      const targetFile = event.target.files[0];
      if (targetFile) {
        debug("targetFile.type", targetFile.type);
        if (!["image/png", "image/jpg", "image/jpeg"].includes(targetFile.type)) {
          setErrorUpload(true);
          return;
        }
        if (targetFile.size > 1_000_000) {
          setErrorUpload(true);
          return;
        }
        setFileToUpload(targetFile);
      }
    }
  };

  return (
    <DashboardLayout
      header={
        <div
          className={clsx(
            "bg-white",
            "w-full p-[20px] md:py-5 px-[40px]",
            "min-h-[127px] md:min-h-[167px]",
            "border-b-[1px] border-gray-200",
            "flex items-center justify-between",
          )}
        >
          <div>
            <div className="text-xs md:text-md text-gray-500">Configuración de cuenta</div>
            <div className="text-lg md:text-3xl mt-[5px] font-bold">Tu información</div>
            <div className="text-xs md:text-md text-gray-400 mt-[5px]">Maneja tus datos</div>
          </div>
        </div>
      }
    >
      <div className="grid grid-cols-3 gap-4 w-full">
        <div
          className={clsx("col-span-3", "rounded-lg", "min-h-[400px]", "border-gray-200", "bg-white", "drop-shadow-md")}
        >
          <div className="bg-gray-200 py-5 px-[40px] text-sm text-gray-600 font-bold">
            Información y Validación Médica
          </div>
          <div className="py-5 px-[40px] grid grid-cols-2 md:grid-cols-3 gap-4">
            <div className="col-span-1">
              <div className="font-semibold text-sm">Título Médico</div>
              <input
                autoFocus={rehydrated && medic && !medic.title}
                name="title"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setMedicData((prevVal) => ({ ...prevVal, title: e.target.value || "" }));
                }}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === "Enter") {
                    updateMedicProfile();
                  }
                }}
                className="mt-2 w-full placeholder:italic placeholder:text-slate-400 block bg-white  border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                value={medicData?.title ? (medicData.title as string) : ""}
              />
            </div>
          </div>
          <div className="py-5 px-[40px] grid grid-cols-2 md:grid-cols-3 gap-4">
            <div className="col-span-1">
              <div className="font-semibold text-sm">Subtítulo</div>
              <input
                name="title"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setMedicData((prevVal) => ({ ...prevVal, subtitle: e.target.value || "" }));
                }}
                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.key === "Enter") {
                    updateMedicProfile();
                  }
                }}
                className="mt-2 w-full placeholder:italic placeholder:text-slate-400 block bg-white  border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                value={medicData?.subtitle ? (medicData.subtitle as string) : ""}
              />
            </div>
            {/* <div className="col-span-1">
              <div className="font-semibold text-sm">Dirección</div>
              <input
                name="address"
                className="mt-2 w-full placeholder:italic placeholder:text-slate-400 block bg-white  border border-slate-300 rounded-md py-2 px-3 shadow-sm focus:outline-none focus:border-sky-500 focus:ring-sky-500 focus:ring-1 sm:text-sm"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setMedicData({
                    ...medicData,
                    address: e.target.value || "",
                  });
                }}
                value={medicData?.address}
              />
            </div> */}
          </div>
          <div className="py-5 px-[40px] grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div className="col-span-1 relative">
              <div className="font-semibold text-sm">Firma de Orden Médica</div>
              <div className={clsx("text-sm")}>
                Si no la tienes puedes dibujarla y descargarla con fondo transparente{" "}
                <a
                  className="text-examedi-blue-strong"
                  href="https://www.signwell.com/online-signature/draw/"
                  target="blank"
                  rel="noopener noreferrer"
                >
                  aquí
                </a>
                .
              </div>
              <input type="file" className="mt-4" onChange={handleFileInputChange} />
              {errorUpload && (
                <div className={clsx("absolute text-sm font-medium text-red-600")}>
                  Intenta con una imagen formato jpg, jpeg o png de menos de 1 MB, por favor.
                </div>
              )}
            </div>
            {/* <div className="mt-4 sm:mt-0 col-span-1 ">
              <div className="font-semibold text-sm">Logo de Orden Médica</div>
              <input type="file" className="mt-4" />
            </div> */}
          </div>
          <div className="py-5 px-[40px] grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <button
              className={clsx(
                "mt-7 md:ml-4",
                "px-4",
                "py-2",
                "text-white",
                "rounded-full",
                "bg-blue-700 hover:bg-blue-500",
                "text-xs",
              )}
              onClick={() => {
                updateMedicProfile();
              }}
              disabled={updating}
            >
              {updating ? <LoadingSpinnerIcon /> : "Actualizar"}
            </button>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default DoctorProfile;
